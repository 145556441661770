(function () {
    function loadScript() {
        if (!document.getElementById('webchat-container')) {
            var createChatContainer = document.createElement('div');
            createChatContainer.id = 'webchat-container';
            createChatContainer.className = 'webchat-container';
            document.body.appendChild(createChatContainer);
        }

        var script = document.createElement('script');
        script.src = '/simplicity/webchat/webchat.bundle.js';
        script.type = 'text/javascript';
        document.body.appendChild(script);

        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/simplicity/webchat/webchat.bundle.css';
        link.type = 'text/css';
        document.head.appendChild(link);

        // Load the New Tobi chat script
        var scriptNew = document.createElement('script');
        scriptNew.src = 'https://web.tobi.vodafone.de/tobi-chat.js';
        scriptNew.type = 'text/javascript';
        scriptNew.onload = function () {
            if ('undefined' !== typeof _tobi) {

                //will be uncomment when inci-4383 fixed
                //loadStylesheet('https://web.tobi.vodafone.de/tobi-chat.css', function () {
                openWebChat();
                //});
            }
        };
        document.body.appendChild(scriptNew);
    }

    function loadStylesheet(href, callback) {
        var linkNew = document.createElement('link');
        linkNew.rel = 'stylesheet';
        linkNew.href = href;
        linkNew.type = 'text/css';
        linkNew.onload = callback;
        document.head.appendChild(linkNew);
    }

    function openWebChat() {
        var webChatAnchors = document.getElementsByClassName('webchat-start-anchor');
        if (0 < webChatAnchors.length) {
            for (var i = 0; i < webChatAnchors.length; i++) {
                (function (anchor) {
                    anchor.addEventListener('click', function (event) {
                        event.preventDefault();
                        if ('function' === typeof _tobi.open) {
                            _tobi.open.call(_tobi);
                        }
                    }, false);
                })(webChatAnchors[i]);
            }
        }
    }

    window.webchatApi = function (webchatConfig, existingOverlayElement) {
        _tobi.open.call(_tobi, webchatConfig, existingOverlayElement);
    };

    loadScript();
})();
